import { EXHIBIT_HALL_BASE_FRAGMENT } from '@/graphql/_Fragments/ExhibitHall/Base';

export const BOOTH_WITH_EXHIBIT_HALL_FRAGMENT = `
  fragment boothWithExhibitHallFragment on Booth {
    uid
    schemaCode
    id
    number
    exhibitHall {
      ...exhibitHallBaseFragment
    }
  }
  ${EXHIBIT_HALL_BASE_FRAGMENT}
`;
